import React from "react";
import { Card, Tab, Nav } from "react-bootstrap";

const Support = () => {
  const tabData = [
    {
      name: "FAQ",
    },
    {
      name: "Videos",
    },
    {
      name: "Onboard Issues",
    },
  ];
  return (
    <Card>
      <Card.Header>
        <Card.Title>Support Content</Card.Title>
      </Card.Header>
      <Card.Body>
        {/* <!-- Nav tabs --> */}
        <div className="custom-tab-1">
          <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
            <Nav as="ul" className="nav-tabs">
              {tabData.map((data, i) => (
                <Nav.Item as="li" key={i}>
                  <Nav.Link eventKey={data.name.toLowerCase()}>
                    {/* <i className={`la la-${data.icon} mr-2`} /> */}
                    {data.name}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
            <Tab.Content className="pt-4">
              {tabData.map((data, i) => (
                <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                  <h4>This is {data.name} title</h4>
                  <p>{data.content}</p>
                  <p>{data.content}</p>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Tab.Container>
        </div>
      </Card.Body>
    </Card>
  );
};
export default Support;
